/* Modern App.css */
:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --background-color: #f5f5f5;
  --text-color: #333;
  --card-background: #fff;
  --card-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;
}

.dark-mode {
  --background-color: #1a1a1a;
  --text-color: #f5f5f5;
  --card-background: #2c2c2c;
  --card-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h1, h2 {
  text-align: center;
  color: var(--primary-color);
}

button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: var(--secondary-color);
}

.movies-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.movie-item {
  background-color: var(--card-background);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: var(--card-shadow);
  transition: transform 0.3s;
  cursor: pointer;
}

.movie-item:hover {
  transform: translateY(-5px);
}

.movie-item img {
  width: 100%;
  height: auto;
}

.movie-item h6 {
  padding: 10px;
  margin: 0;
  font-size: 14px;
  text-align: center;
}

form {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

input[type="text"] {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px 0 0 5px;
  width: 300px;
}

form button {
  border-radius: 0 5px 5px 0;
}

.movie-details {
  background-color: var(--card-background);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: var(--card-shadow);
}

.movie-details img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.ratings {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.rating-item {
  text-align: center;
}

.rating-item img {
  width: 50px;
  height: auto;
}